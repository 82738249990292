@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .section {
    @apply container mx-auto px-4 py-8;
  }
  .section-fluid {
    @apply w-full mx-auto px-4 py-8;
  }
  .btn-primary {
    @apply bg-primary hover:bg-complementary text-white border-0 rounded px-4 py-2 text-center;
  }
  .btn-white {
    @apply bg-white hover:bg-neutral-50 text-black border-0 rounded px-4 py-2 text-center;
  }
  .form-group {
    @apply mb-4;
  }
  .form-label {
    @apply block mb-2;
  }
  .form-input {
    @apply border rounded px-4 py-2 w-full;
  }
  .form-control {
    @apply form-input;
  }
  .btn {
    @apply px-4 py-2 rounded transition-all duration-150 hover:border hover:border-secondary;
  }
}

/* Animación del carrusel */
.fade-anim img {
  animation: fadeAnim 2s ease-in-out;
}
@keyframes fadeAnim {
  from {
    /* left: 100%; */
    opacity: 0;
  }
  to {
    /* left: 0; */
    opacity: 1;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}