/* * Progress Bar */

.progress {
  height: 10px;
}


/* * Button [-][+] */

.btnMiniPlusMinus {
  width: 150px;
}

/* Firefox
.quantityMiniButtonPlusMinus {
-moz-appearance: textfield;

font-size: 1rem;
width: 26px;
}

.quantityMiniButtonPlusMinus::-webkit-outer-spin-button,
.quantityMiniButtonPlusMinus::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  
  font-size: 1rem;
  width: 26px;
} */



/* ! Responsive */

@media screen and (max-width: 768px) {
  /* * Product Card */
  .cartProductInputContainer {
      padding-left: calc((var(--bs-gutter-x) * .5) + 50px);
  }
}